.root {
  display: flex;
  flex-flow: column;
  height: 100%;

  .content {
    flex-grow: 1;
    overflow: hidden;
  }

  overflow: hidden;

  .toolbar {
    //background-color: red;

    .logoName {
      margin-right: 16px;
      display: flex;
      font-family: monospace;
      font-weight: 700;
      letter-spacing: .2rem;
      color: inherit;
      text-decoration: none;

      .alpha {
        font-size: 0.6em;
        position: absolute;
        transform: translateX(-50%) translate(20px, 10px) rotate(-45deg);
        top: 0;
        left: 0;

        background: #29b6f6;
        width: 200px;
        color: white;

        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

}


.menu {
  overflow: visible !important;
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.32));
  margin-top: 6px;

  & :global(.MuiAvatar-root) {
    width: 32px;
    height: 32px;
    margin-left: -4px;
    margin-right: 8px;
  }

  &:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    right: 24px;
    width: 16px;
    height: 16px;
    //bgcolor: background.paper',
    background-color: inherit;
    //background-color: red;
    transform: translateY(-50%) rotate(45deg);
    z-index: 0;
  }

}