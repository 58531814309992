html {
  height: 100%;
}
body {
  margin: 0;
  padding: 0;
  border: 0;
  min-height: 100%;

  background-color: #121212;
  overflow: hidden;
}

#root {
  height: 100vh;
  width: 100%;
}

* {
  box-sizing: border-box;
}

.MuiTooltip-tooltip {
  max-width: unset!important;
}